<template>
<div class="search_container searchArea">
    <el-form :inline="true" :model='searchInfo' ref="searchInfo" :rules="rules" :label-position="labelPosition" class="demo-form-inline search-form">
        <div class="search_form_item">
            <el-form-item prop="name" label="模板名称">
                <el-input v-model="searchInfo.lateFeeName" placeholder="请输入模板名称" @change="templateNameChange" clearable></el-input>
            </el-form-item>
            <el-form-item prop="name" label="起算类型">
                <el-select v-model="searchInfo.startType" placeholder="请选择起算日起" clearable  @change="startTypeChange">
                    <el-option v-for="item in qslxData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name" label="周期模式">
                <el-select v-model="searchInfo.cycleType" placeholder="请选择周期模式" clearable  @change="cycleTypeChange">
                    <el-option v-for="item in zqmsData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name" label="启用状态">
                <el-select v-model="searchInfo.status" placeholder="请选择启用状态" clearable  @change="statusChange" >
                    <el-option v-for="item in qdztData" :key="item.id" :label="item.qdztname" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="search-btn">
                <el-button type="success" size="mini" icon="el-icon-search" plain @click="searchList()">查询</el-button>
                <!-- <el-button size="mini" icon="el-icon-refresh-left" plain @click="addKC()">重置</el-button> -->
                <el-button type="success" size="mini" icon="el-icon-plus" plain @click="addKC()">新建</el-button>
            </el-form-item>
        </div>
    </el-form>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: 'searchItem',
    props: {
        searchInfo: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            labelPosition: 'left',
            rules: {},
            qslxData:[
                {
                    name: "自出账日多少天后",
                    id: 1
                }
            ],
            zqmsData:[
                {
                    name: "日",
                    id: 1
                },
                {
                    name: "月",
                    id: 2
                },
                {
                    name: "年",
                    id: 3
                }
            ],
        }
    },
    computed: {},
    created() {},
    methods: {
        // 查询
        searchList() {
            this.$emit('searchList', this.searchInfo)
        },
        addKC() {
            this.$emit('addTemplate')
        },
        //有效期设置
        yxqsz() {
            this.$emit('yxqSetting')
        },
        templateNameChange(e){
            this.searchInfo.lateFeeName = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        startTypeChange(e){
            this.searchInfo.startType = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        cycleTypeChange(e){
            this.searchInfo.cycleType = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        statusChange(e) {
            this.searchInfo.status = e
            this.$emit('update:searchInfo', this.searchInfo)
        }
    }
}
</script>

<style>
.search_container {
    padding: 20px 0 0px;
    /* border-bottom: 1px solid #eff1f4; */
}
</style>
